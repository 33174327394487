import {useEffect, useState} from "react";
import Analytics from "../../../lib/analytics/Analytics";
import {IdentifyLanguageClick, LanguageToggleClick} from "../../../lib/analytics/AnalyticsEvents";
import {ILanguageToggleData} from "../../../lib/types/common";
import {IToggleDataProps} from "../../../lib/types/components";
import {allLocales, COUNTRY} from "../../../lib/utils/constants/LocaleAndCountryConstants";
import {getCountryFromLocale, getUrlSegments, isMobile} from "../../../lib/utils/constants/PagesConstants";
import CustomLinkComponent from "../CusotmLink/CustomLinkComponent";
import CustomImageComponent from "../CustomImage/CustomImageComponent";
import styles from "./LanguageToggleComponent.module.css"
import {multipleClassName} from "../../../lib/utils/helpers/helper";

const LanguageToggleComponent = (props: IToggleDataProps) => {

    const {menuOpen, handleHamburgerClick, selectLanguageText} = props;
    const languageToggleData: ILanguageToggleData = props.languageToggleData
    let {locale} = props;

    const [isToggleOpen, setIsToggleOpen] = useState(false);
    // useEffect(() => {
    //     if (window) {
    //         document.addEventListener('scroll', () => setIsToggleOpen(false))
    //     }
    //     return document.removeEventListener('scroll', () => setIsToggleOpen(false))
    // }, [])

    useEffect(() => {
        if (isToggleOpen && menuOpen) {
            setIsToggleOpen(false);
        }
    }, [menuOpen])

    //Not showing toggle for us for now as there is only one language
    const availableLanguages = languageToggleData.toggleData.availableLanguages.filter((countryLang) => {
        return getCountryFromLocale(locale) === getCountryFromLocale(countryLang.locale)
    });

    const toggleClick = () => {
        if (!isToggleOpen) {
            Analytics.getInstance().logEvent(LanguageToggleClick);
        }
        if (menuOpen) {
            handleHamburgerClick();
        }
        setIsToggleOpen(!isToggleOpen);
    }

    const segmentsArray = getUrlSegments();
    // trim path if locale is present
    let path = allLocales.includes(segmentsArray[0]) ? segmentsArray.slice(1).join('/') : segmentsArray.join('/');

    if (availableLanguages.length <= 1) return <></>;

    const getClassName = (language: string) => {
        const length = availableLanguages?.length;
        let classToggle = styles.anchorTag;
        if (length < 4 && language === locale) {
            classToggle = multipleClassName(styles.anchorTagLessLang, styles.active)
        } else if (length <= 4) {
            classToggle = styles.anchorTagLessLang
        } else if (length > 4 && language === locale) {
            classToggle = multipleClassName(styles.anchorTag, styles.active)
        }
        return classToggle
    }

    const onLanguageClick = (lang: string) => {
        const language = lang.toUpperCase();
        Analytics.getInstance().logEvent(IdentifyLanguageClick(language));
        setIsToggleOpen(false);
    }

    return (
        <>
            <div className={isMobile ? "" : styles.mainContainerToggle}>
                {
                    <div className={styles.imgClass} onClick={toggleClick}>
                        <CustomImageComponent
                            height={20}
                            width={20}
                            src={getCountryFromLocale(locale) === COUNTRY.INDIA ? "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/langToggle.webp" : "https://d3g4wmezrjkwkg.cloudfront.net/website/images/clock_fill.png"}
                            alt="winzo games toggle"
                            // priority={true}
                        />
                    </div>
                }

                <div className={styles.dropdownContent} style={{display: isToggleOpen ? 'flex' : 'none'}}>
                    <div className={styles.selectLang}>{selectLanguageText || "SELECT YOUR LANGUAGE"}</div>
                    {availableLanguages && availableLanguages.map((drop, index) => {
                        return (
                            <CustomLinkComponent href={"/" + path}
                                                 locale={drop.locale}
                                                 className={locale === drop.locale ? styles.active : ""} key={index}
                                                 desktopStyle={{
                                                     color: "white",
                                                     width: "50%",
                                                     textDecoration: "none",
                                                     display: "block",
                                                     textAlign: "center"
                                                 }}
                                                 mobStyle={{
                                                     color: "white",
                                                     width: `${getClassName(drop.locale).includes(styles.anchorTagLessLang) ? "50%" : "20%"}`,
                                                     textDecoration: "none",
                                                     display: "block",
                                                     textAlign: "center"
                                                 }}
                            >
                                        <span className={getClassName(drop.locale)}
                                              onClick={() => onLanguageClick(drop.locale)}
                                              key={index}>{drop.langugage}</span>
                            </CustomLinkComponent>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default LanguageToggleComponent;