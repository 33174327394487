import { dynamicCssSize } from "../../../lib/utils/constants/PagesConstants";
import { BANNER_DOWNLOAD_BUTTON_TYPE, HEADER_DOWNLOAD_BUTTON_TYPE } from "../../../lib/utils/constants/GlobalConstants";
import React, {useRef} from "react";
import ButtonClickAnimation from "../../animation/ButtonClickAnimation";

const GDCSubmitButton = (props) => {
    const { type, text, sendAnalytics, disabled=false } = props;
    let anchorRef: any = useRef(null);
    const handleClick = () => {
        anchorRef.current.click();
        sendAnalytics();
    };
    return (
        <div>
            <style jsx>
                {`
                    .buttonContainer {
                        display: block;
                        margin-top: ${type === HEADER_DOWNLOAD_BUTTON_TYPE
                            ? "0px"
                            : "15px"};
                        cursor: ${disabled ? "auto" : "pointer"};
                    }

                    .buttonContainer:hover {
                        text-decoration: none;
                    }

                    .iosButtonContainer {
                        display: flex;
                        justify-content: center;
                    }

                    .iosButtonContainer > a > img {
                        width: 100%;
                    }

                    .mainContainer {
                        font-family: var(--font-primary);
                        background: ${disabled
                            ? "linear-gradient(to bottom,#eee,#ccc)"
                            : "linear-gradient(to bottom,#b1fd2b,#31eb6f)"};
                        border-radius: 6px;
                        box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.5);
                        position: relative;
                        transform: skewX(0deg);
                        padding: 1px;
                        margin: 0 5px;
                        overflow: hidden;
                    }

                    .content {
                        background-image: ${disabled
                            ? "linear-gradient(to bottom,#eee,#ccc),linear-gradient(to bottom, #eee, #ccc)"
                            : "linear-gradient(to bottom,#57cc03,#004f1c),linear-gradient(to bottom, #b1fd2b, #31eb6f)"};
                        font-size: ${type === BANNER_DOWNLOAD_BUTTON_TYPE
                            ? dynamicCssSize(36, 18)
                            : type === HEADER_DOWNLOAD_BUTTON_TYPE
                            ? dynamicCssSize(18, 9)
                            : dynamicCssSize(24, 12)};
                        border-radius: 6px;
                        padding: ${type === BANNER_DOWNLOAD_BUTTON_TYPE
                            ? "3px"
                            : "6px"};
                        min-width: ${type === BANNER_DOWNLOAD_BUTTON_TYPE
                            ? dynamicCssSize(450, 100)
                            : type === HEADER_DOWNLOAD_BUTTON_TYPE
                            ? dynamicCssSize(150, 50)
                            : dynamicCssSize(250, 80)};
                        width: 100%;
                        height: 100%;
                        font-weight: 600;
                        text-align: center;
                        color: #ffffff;
                        font-style: italic;
                        letter-spacing: -0.32px;
                        text-transform: uppercase;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .content p {
                        margin: 0;
                        transform: skewX(7deg);
                    }

                    .arrow-3 {
                        display: flex;
                    }

                    .arrow-3-container span {
                        height: 4px;
                        width: 24px;
                        display: block;
                        overflow: hidden;
                        background-color: #ffffff;
                    }

                    @keyframes a3 {
                        0% {
                            transform: translateY(-4px);
                        }
                        100% {
                            transform: translateY(2px);
                        }
                    }

                    @keyframes ellipsis-animation {
                        0% {
                            clip-path: inset(0 8.5% 0 0);
                        }
                        25% {
                            clip-path: inset(0 6% 0 0);
                        }
                        50% {
                            clip-path: inset(0 3% 0 0);
                        }
                        75% {
                            clip-path: inset(0 0 0 0);
                        }
                    }
                    .shimmer {
                        width: 100%;
                        background-size: 1rem 1rem;
                        animation-duration: 6s;
                        animation-fill-mode: forwards;
                        animation-iteration-count: infinite;
                        animation-name: shimmerAnimation;
                        animation-timing-function: linear;
                        background: linear-gradient(
                                to right,
                                rgba(255, 255, 255, 0),
                                rgba(255, 255, 255, 0.8),
                                rgba(255, 255, 255, 0)
                            )
                            no-repeat 0 0;
                        position: absolute;
                        top: 1px;
                        bottom: 1px;
                        left: 1px;
                        right: 1px;
                        border-radius: 6px;
                    }
                    @-webkit-keyframes shimmerAnimation {
                        0% {
                            background-position: -1200px 0;
                        }
                        100% {
                            background-position: 1200px 0;
                        }
                    }

                    @keyframes shimmerAnimation {
                        0% {
                            background-position: -1200px 0;
                        }
                        100% {
                            background-position: 1200px 0;
                        }
                    }
                    @media screen and (max-width: 767px) {
                        .buttonContainer {
                            width: ${type === BANNER_DOWNLOAD_BUTTON_TYPE
                                ? "70vw"
                                : "100%"};
                        }

                        .fixedDownloadButtonContainer {
                            display: block;
                        }

                        .iosButtonImage_FB {
                            height: 42px;
                        }
                    }

                    @keyframes placeholderShimmer2 {
                        0% {
                            background-position-x: -10rem;
                        }
                    }
                `}
            </style>

            <>
                <ButtonClickAnimation>
                    <span onClick={(event) => handleClick()}>
                        <span className={"buttonContainer"}>
                            <div className={"mainContainer"}>
                                <div className={"content"}>
                                    <div className={"shimmer"}></div>

                                    <p>{text}</p>
                                </div>
                            </div>
                        </span>
                    </span>
                </ButtonClickAnimation>
                <div style={{ display: "none" }}>
                    <a
                        href={props.url}
                        ref={anchorRef}
                        suppressHydrationWarning
                        target="_blank"
                    ></a>
                </div>
            </>
        </div>
    );
};

export default GDCSubmitButton;
