import {IHamburgerComponentProps} from "../../../lib/types/components";
import {multipleClassName} from "../../../lib/utils/helpers/helper";
import styles from "./HamburgerComponent.module.css"

const HamburgerComponent = (props: IHamburgerComponentProps) => {

    const {menuOpen, handleHamburgerClick} = props;

    return (
        handleHamburgerClick ?
            <div
                onClick={() => handleHamburgerClick()}
                className={menuOpen ? multipleClassName(styles.hamburgerContainer, styles.open) : styles.hamburgerContainer}
            >
                <span/>
                <span/>
                <span/>
                <span/>
            </div>
            : <></>
    )
}

export default HamburgerComponent;